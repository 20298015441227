<template>
  <div class="home">
    <div class="tab_bar">
      <div
        :class="[active == 0 ? 'tab_text tab_text_active' : 'tab_text']"
        @click="change(0)"
      >
        报告详情
      </div>
      <div
        @click="change(1)"
        :class="[active == 1 ? 'tab_text tab_text_active' : 'tab_text']"
      >
        报告总结
      </div>
    </div>
    <div v-show="active == 0" class="flex2">
      <div id="echarts" class="echarts"></div>
      <div class="tab_content secondary_text">
        <div v-for="(item, idx) in reportcontext" :key="idx">
          <item-wrap :title="item.typename" :score="item.quotascore + '分'">
            <div class="report-tabel">
              <span class="report-label">测评结果 </span>
              <span class="report-result">{{ item.result }}</span>
            </div>
            <div class="report-tabel">
              <span class="report-label">测评建议 </span>
              <span class="report-result">
                <div>{{ item.proposal }}</div>
                <div v-if="item.typecode == '005'">
                  <div v-for="(subItem, index) in item.quotaList" :key="index">
                    <div class="reportItemTitle">
                      {{ index + 1 + "." }}{{ subItem.quotaname }} -
                      <span class="tab_text_active"
                        >{{ subItem.quotascoreaverage }}分</span
                      >
                    </div>
                    <div
                      style="margin-left: 10px; font-size: 14px"
                      class="regular_text"
                    ></div>
                    <div class="progress-center">
                      <el-progress
                        color="#FEA2BB"
                        :percentage="Number(subItem.quotascore)"
                        :show-pivot="false"
                        :show-text="false"
                        :format="format"
                        class="progress"
                      />
                    </div>

                    <div class="scl90_item">
                      {{ subItem.proposal ? subItem.proposal : "无内容" }}
                    </div>
                    <div style="height: 20px; background: #fff"></div>
                  </div>
                </div>
              </span>
            </div>
          </item-wrap>
        </div>

        <!-- <div v-for="(item, idx) in reportcontext" :key="idx">
        <div class="exam_title">{{ item.typename }}</div>
        <div class="reportItemTitle">评测结果: {{ item.result }}</div>
        <div class="reportItemTitle">评测建议: {{ item.proposal }}</div> -->
      </div>
    </div>
    <div v-show="active == 1" class="flex2">
      <item-wrap :title="'总结'">
        <div class="report-tabel">
          <span class="report-label">测评结果 </span>
          <span class="report-result">{{ detail.assessgrade }}</span>
        </div>
        <div class="report-tabel">
          <span class="report-label">报告总结 </span>
          <span class="report-result">{{ detail.assessdetail }}</span>
        </div>
      </item-wrap>
    </div>
  </div>
</template>

<script>
// import ItemBasic from "./item-basic";
import ItemWrap from "./item-wrap";
// import ItemAudit from "./item-audit";
import * as echarts from "echarts";
export default {
  name: "Home",
  components: { ItemWrap },
  data() {
    return {
      myChart: "",
      detail: "",
      reportcontext: [],
      active: 0,
    };
  },
  mounted() {
    this.openid = this.$route.query.openid;
    this.reportid = this.$route.query.reportid;
    this.myChart = echarts.init(document.getElementById("echarts"));
    this.$api
      .getReportEcharts({
        openid: this.openid,
        reportid: this.reportid,
      })
      .then((res) => {
        if (res.data.success) {
          this.detail = res.data.data;
          // setTimeout(() => {
          this.initEcharts(this.detail);
          // }, 500);
        }
      });
  },
  methods: {
    change(e) {
      this.active = e;
    },
    format(percentage) {
      return percentage === 100 ? "" : ``;
    },
    // 获取最大值
    getMaxV(distributionInfo) {
      let max = 0;
      for (let item of distributionInfo) {
        if (max < Number(item)) max = Number(item);
      }
      return max;
    },

    // 获取最小值
    getMinV(distributionInfo) {
      let min = 1000000;
      for (let item of distributionInfo) {
        if (min > Number(item)) min = Number(item);
      }
      return min;
    },

    // 归一化处理
    normalization(distribution, max, min) {
      let normalizationRatio = (distribution - min) / (max - min);
      return normalizationRatio;
    },

    initEcharts(detail) {
      const reportcontext = detail.reportcontext.splice(1);

      const indicator = reportcontext.map((e) => {
        return {
          text: e.typename,
        };
      });
      let progress = reportcontext.map(
        (e) => Number(Number(e.quotascore) / Number(e.quotatotalpoints)) * 100
      );
      let data2 = reportcontext.map((e) => Number(e.quotatemplate));
      this.reportcontext = reportcontext;
      console.log(indicator);
      this.myChart.setOption({
        title: {
          text: "",
        },
        // tooltip: {
        //   trigger: "item",
        //   confine: true, // 加入这一句话
        //   position: ["0%", "50%"],
        // },
        color: ["#ff84a6", "#32cd32"],
        legend: {
          itemHeight: 6, //图例的高度
          itemGap: 20, //图例间距
          itemWidth: 10, //图例的宽度
          textStyle: {
            //整体设置文字部分的样式
            color: "#666",
            fontSize: 16,
            fontweight: 400,
            height: 30,
            lineHeight: 30,
            margin: 30,
          },
          data: ["测评结果", "常规值"],
        },
        radar: [
          {
            indicator,
            // center: ["50%", "50%"],
            radius: 100,
            startAngle: 90,
            splitNumber: 4,
            shape: "radar",
            name: {
              formatter: (text) => {
                text = text.replace(/\S{6}/g, function (match) {
                  console.log(match);
                  return match + "\n";
                });
                return text;
              },
              textStyle: {
                color: "#72ACD1",
              },
            },
            splitArea: {
              areaStyle: {
                color: [
                  "rgba(114, 172, 209, 0.2)",
                  "rgba(114, 172, 209, 0.4)",
                  "rgba(114, 172, 209, 0.6)",
                  "rgba(114, 172, 209, 0.8)",
                  "rgba(114, 172, 209, 1)",
                ],
                shadowColor: "rgba(0, 0, 0, 0.3)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
            },
          },
        ],
        series: [
          {
            name: "雷达图",
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                value: progress,
                name: "测评结果",
                symbol: "rect",
                lineStyle: {
                  color: "#ff84a6",
                },
              },
              {
                value: data2,
                name: "常规值",
                lineStyle: {
                  type: "dashed",
                  color: "#32cd32",
                },
                areaStyle: {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              },
            ],
          },
        ],
      });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep#app {
  height: 100%;
}
.home {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tab_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  text-align: center;
  border-bottom: 1px #c0c4cc solid;
}
.tab_text {
  flex: 1;
  line-height: 50px;
}
.flex2 {
  flex: 1;
  height: 100%;
  overflow: scroll;
}
.tab_text_active {
  color: #ff84a6;
}
.tab_content {
  position: relative;
  /* height: 500px; */
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  margin-bottom: 75px;
  /* text-align: center; */
  padding-top: 15px;
}
.progress-center {
  text-align: center;
  width: 100%;
}
.progress {
  margin: 10px 0;
  width: 100%;
}
ec-canvas {
  width: 100%;
  height: 250px;
}
.reportTextDes {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-indent: 2em;
  font-size: 16px;
}
.reportItemTitle {
  /* display: flex; */
}
.report-tabel {
  padding: 5px 15px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  display: flex;
}
.report-label {
  width: 100px;
  display: inline-block;
  color: #333;
  font-size: 14px;
}
.report-result {
  color: #333;
  flex: 1;
  width: 0;
}
.reportTitle {
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;

  height: 500px;
  line-height: 500px;
}

.scl90_item {
  /* margin: 10px; */
}

.echarts {
  height: 320px;
}
.scl90_container {
  margin-top: 25px;
}

.exam_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.footer_toolbar {
  z-index: 999;
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f4f4f4;
  flex-direction: row;
  align-items: center;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.full_button {
  width: 80%;
  margin: 0 auto;
}
</style>
