<template>
  <div class="item-wrap">
    <div class="item-wrap-title">
      <div class="line"></div>
      {{ title }}
      <span class="score">{{ score }}</span>
    </div>
    <div class="item-wrap-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    score: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.item-wrap {
  background-color: #fff;
  .score {
    color: #ff84a6;
    margin-left: 10px;
  }
  &-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    height: 45px;
    border-bottom: 1px solid #eee;

    .line {
      width: 3px;
      height: 13px;
      background: #319cfc;
      opacity: 1;
      border-radius: 3px;
      margin-left: 15px;
      margin-right: 10px;
    }
  }

  &-content {
    padding: 0;
  }
}
</style>